import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import styles from "../NACustomerOrders.module.scss";
import HasPermissionTo from "../../../common/HasPermissionTo";
import { withTranslation } from "react-i18next";
import UpperCaseText from "../../../utils/UpperCaseText";
import TabsPagination from "./TabsPagination";
import ExpandableTableRow from "../../../shared/components/EMPExpandableTableRow/EMPExpandableTableRow";
import { withStyles } from "@material-ui/core/styles";
import GetDeliveryStatus from "../../../utils/DeliveryStatusUtils/GetDeliveryStatus";
import EMPChip from "../../../shared/components/EMPChip/EMPChip";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import { PIPELINE } from "../../../common/Constants";
import { useQuery } from "@tanstack/react-query";
import CustomerReservationsClient from "../../../clients/CustomerReservationsClient";
import EMPLoader from "../../../shared/components/EMPLoader/EMPLoader";
import DealerReportingClient from "../../../clients/DealerReportingClient";
import DealerDeliveryToolClient from "../../../clients/DealerDeliveryToolClient";

const StyledTableHead = withStyles({
  root: {
    fontFamily: "'FordAntennaBold', sans-serif",
    fontSize: 14,
    color: "#102b4e",
    padding: "16px 8px 8px 8px",

    "&:first-of-type": {
      padding: "16px 8px 8px 8px",
      width: 70,
    },
  },
})(TableCell);

const ModelEOrders = (props) => {
  const [openRows, setOpenRows] = useState({});
  const [allRowsExpanded, setAllRowsExpanded] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    AnalyticsService.trackPageLoadEvent("fv:emp:model e", props.user);
  }, [props.user]);

  const {
    isLoading: loadingOrders,
    data: orders,
    isError: ordersError,
    isFetching,
  } = useQuery({
    queryKey: [
      "orders",
      { tab: "model_e_orders", page, commonId: props.user.commonDealerId },
    ],
    queryFn: () =>
      CustomerReservationsClient.getCustomerOrders(
        props.user.token,
        props.user.commonDealerId,
        "model_e_orders",
        props.user.lang,
        page,
        "byDate"
      ),
  });

  const gatherOrderIdList = (orders) => {
    const orderIds = orders.map((order) => order.id).join(",");
    return DealerReportingClient.getCustomerNames(props.user, orderIds);
  };

  const { isFetching: namesLoading, data: names } = useQuery({
    queryKey: [
      "customerNames",
      { tab: "model_e_orders", page, commonId: props.user.commonDealerId },
    ],
    queryFn: () => gatherOrderIdList(orders.reservations),
    enabled: !!orders,
    select: ({ data }) => data.customerNameMap,
  });

  const {
    isFetching: loadingAppointments,
    data: appointmentData,
    isError: appointmentsError,
  } = useQuery({
    queryKey: ["appointments", props.user.commonDealerId],
    queryFn: () =>
      DealerDeliveryToolClient.getDeliveryAppointments(
        props.token,
        props.user.dealerId
      ),
    select: ({ data }) => {
      return data.appointmentsByOrderId;
    },
    staleTime: Infinity,
  });

  const handleRow = (id) => {
    const isOpen = !!openRows[id];

    setOpenRows({
      ...openRows,
      [id]: !isOpen,
    });
  };

  const controlAllRows = (rows, value) => {
    const allRows = {};
    rows.forEach((row) => (allRows[row.id] = value));

    setAllRowsExpanded(value);
    setOpenRows({
      ...openRows,
      ...allRows,
    });
  };

  const getAppointmentDate = (appointment) =>
    appointment && appointment.date ? appointment.date : null;

  const validAppointment = (order, key) => {
    return (
      appointmentData &&
      appointmentData[order.id] &&
      appointmentData[order.id][key]
    );
  };

  const modelELabel = (order) => {
    const isModelE =
      order.journeyType === "Model-E" || order.journeyType === "Model-e";

    if (isModelE && order["spotBuy"]) {
      return (
        <EMPChip label="In store" labelColor="primary" testId="order-label" />
      );
    } else if (
      isModelE &&
      UpperCaseText(order["subJourneyType"]) === PIPELINE
    ) {
      return (
        <EMPChip label="From plant" labelColor="primary" testId="order-label" />
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.modeleTableWrapper}>
      <Grid
        container
        className={styles.expandContainer}
        justifyContent="flex-end"
      >
        <Grid
          item
          onClick={() => controlAllRows(orders.reservations, !allRowsExpanded)}
        >
          {`${allRowsExpanded ? "Collapse" : "Expand"} all rows`}
        </Grid>
      </Grid>

      <TableContainer component={Paper} square>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <StyledTableHead></StyledTableHead>
              <StyledTableHead>
                {props.t("ModelEOrders.customer")}
              </StyledTableHead>
              <StyledTableHead>
                {props.t("ModelEOrders.delivery")}
              </StyledTableHead>
              <StyledTableHead>
                {props.t("ModelEOrders.Action")}
              </StyledTableHead>
              <StyledTableHead></StyledTableHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingOrders || loadingAppointments || isFetching ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Grid container justifyContent="center">
                    <Grid item>
                      <EMPLoader loadingMessage="Loading orders" />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : ordersError || appointmentsError ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Grid container justifyContent="center">
                    <Grid item>
                      <div>
                        Something went wrong. Please refresh the page to try
                        again.
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ) : (
              orders.reservations.map((order, index) => {
                return (
                  <ExpandableTableRow
                    id={"row" + index}
                    key={order.id}
                    order={order}
                    orderLabel={modelELabel(order)}
                    status={GetDeliveryStatus(
                      validAppointment(order, "deliveryAppointment")
                    )}
                    appointmentDate={getAppointmentDate(
                      validAppointment(order, "deliveryAppointment")
                    )}
                    customerName={
                      namesLoading ? (
                        <Skeleton animation={false} width={100} />
                      ) : (
                        names[order.id]
                      )
                    }
                    itemNumber={index}
                    onShow={() => handleRow(order.id)}
                    isOpen={!!openRows[order.id]}
                    rowLink={`/dealer/${order.dealerId}/customer-reservations/model-e-details/${order.id}`}
                    data-testid="expandableTableRow"
                  ></ExpandableTableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!props.hidePagination && (
        <HasPermissionTo
          perform={["showCustomerHandlingPagination"]}
          permissions={props.user.permissions.rolePermissions}
          condition={!!orders}
          render={() => (
            <TabsPagination
              numberOfPages={orders.pagination.numberOfPages}
              currentPage={orders.pagination.currentPage}
              handleSpecificPage={(newPage) => setPage(newPage)}
              handlePrevious={() => setPage((old) => Math.max(old - 1, 0))}
              handleNext={() => setPage((old) => old + 1)}
            />
          )}
        />
      )}
    </div>
  );
};

export default withTranslation("emp")(ModelEOrders);
